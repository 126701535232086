import React from 'react';

const News = () => {
    return(
        <div className="blurrywhite">
            <p>Here are some relevant news items!</p>
        </div>
    )
}

export default News;
